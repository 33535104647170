import React, { Component } from 'react';
import MainHeader from '../../../components/common/MainHeader';
import FooterDesktop from '../../../components/common/FooterDesktop_bhutan';
import Bagdogra_6N_7D_Package from '../../../components/package/Bhutan/Bagdogra_6N_7D_Package';
import { Helmet } from "react-helmet";
class Bagdogra_6N_7D_Page extends Component {
    constructor() {
        super();
        this.state = {
            HotPackagesData: [],
            ReviewersData: [],
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        const hotPackageDataList = [
            {
                Title: "Blissful Bhutan",
                ImageName: "6N-7D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 1N Punakha | 1N Paro...more | Free Sim Card | E-Permit",
                PricePerNight: "29,900/-",
                NoOfTours: 43,
                NoOfDeparture: 38,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head 6 Guests",
                book: "Book Now",
                EXPARO: "EX-IXB",
                Url: 'tel://9674738480'
            },
            {
                Title: "Untouched Bhutan",
                ImageName: "7N-8D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "3N Thimphu | 2N Paro...more | Free Sim Card | E-Permit",
                PricePerNight: "35,100/-",
                NoOfTours: 47,
                NoOfDeparture: 40,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head 6 Guests",
                book: "Book Now",
                EXPARO: "EX-IXB",
                Url: 'tel://9674738480'
            },
            {
                Title: "Explore Bhutan",
                ImageName: "8N-9D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 2N Paro | Free Sim Card | E-Permit",
                PricePerNight: "40,200/-",
                NoOfTours: 44,
                NoOfDeparture: 37,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head 6 Guests",
                book: "Book Now",
                EXPARO: "EX-IXB",
                Url: 'tel://9674738480'
            },
            {
                Title: "Excape Bhutan",
                ImageName: "10N-11D-Bhutan-Tour.webp",
                AllternateText: "...",
                Description: "2N Thimphu | 2N Punakha | 3N Paro...more | Free Sim Card | E-Permit",
                PricePerNight: "48,200/-",
                NoOfTours: 46,
                NoOfDeparture: 39,
                SDFInclude: "SDF Inc.",
                MutedText: "*Per head 6 Guests",
                book: "Book Now",
                EXPARO: "EX-IXB",
                Url: 'tel://9674738480'
            }

        ];

        const reviewersDataList = [
            {
                Name: "Krishna K",
                ImageName: "Krishna-K.webp",
                AllternateText: "...",
                Location: "@Delhi",
                Comment: "Our Bhutan trip from Bagdogra was organised by Soumalya , Rewa Addorable LLP. Simply great and exotic hotels. Stay was very luxurious, food was great. Traveller bus was very smooth and luxurious. No issues with anything. The team was constantly in touch with us during the tour on WhatsApp to check everything is fine. They are at their best to provide best services to their guests. Very cooperative staff. Our guide Mr Harka and Mr Donzy were so friendly and awesome guys. Mr Soumalya was prompt , approchable , and provided a great deal and comfortable plan for us Once again would like to thank personally Mr Soumalya & their team members for arranging the best tour of Bhutan Will definitely recommend them to everyone for a memorable trip.",
            },
            {
                Name: "Sumit Mahata",
                ImageName: "sumit_meheta.webp",
                AllternateText: "...",
                Location: "@Delhi",
                Comment: "What an awesome trip! It helps that Bhutan is probably the most beautiful place on the planet,but more importantly, our travel agency arranged our access to the country’s wonders so seamlessly. All thanks to Soumlya , sneheta and sonali, Keep up the good work.",
            },
            {
                Name: "Ramesh Nair",
                ImageName: "ramesh-nair.webp",
                AllternateText: "...",
                Location: "@Chennai",
                Comment: "My wife and I traveled to Bhutan from 7th October to 12th October and the trip was arranged by Adorable Vacation. We are pretty satisfied with the arrangements and will recommend Adorable Vacation to people who are planning on travelling to Bhutan.The fact that we had a great team made our experience even more memorable.",
            },
            {
                Name: "Ruchir Mehta",
                ImageName: "ruchir_mahata.webp",
                AllternateText: "...",
                Location: "@Mumbai",
                Comment: "Had the most comfortable stay in Bhutan, thanks to adorable vacation LLP. Our guide Mr.Gyeltshen and our driver were extremely helpful and nice. They were always ready to help us out with everything. I would highly recommend Adorable Vacation LLP to everyone and would love to book our next trip with them too. Thank you team for taking care of us",
            },
        ]


        this.setState({
            HotPackagesData: hotPackageDataList,
            ReviewersData: reviewersDataList
        })
    }

    render() {
        const organizationSchema = {
            "@context": "https://schema.org",
            "@type": "TravelAgency",
            "url": "https://adorablevacation.com",
            "name": "Adorable Vacation LLP",
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91 9883359713",
                "contactType": "Customer Service",
                "areaServed": "Country",
                "availableLanguage": "Hindi, Tamil, English, Bengali,Malayam"
            },
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "ground floor, 5, Dwarik Ganguly St",
                "addressLocality": "Kolkata",
                "addressRegion": "West Bengal",
                "postalCode": "700026",
                "addressCountry": "India"
            },
            "openingHours": "Mo-Sat 10:00-19:00",
            "description": "We Offer the Best Travel Experinces and Luxery Tour Organizer. For Booking ☎ CALL Mr. SOUMALYA +91-98833-59713",
            "sameAs": [
                "https://www.facebook.com/adorablevacationllp/",
            ]
        };
        return (
            <>
                <Helmet>
                    <link rel="canonical" href="https://adorablevacation.com/bhutan-tour-package-from-bagdogra" />
                    <title>Bhutan package Tour from Bagdogra – Best Deal from Adorable Vacation LLP </title>
                    <meta name="description" content="Book Bhutan Tour Package from Bagdogra bast deal from Adorable Vacation LLP. Call Now: +91-98833-59713" />
                    <script type="application/ld+json">
                        {JSON.stringify(organizationSchema)}
                    </script>
                    <meta property="og:title" content="Bhutan package Tour from Bagdogra" />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://adorablevacation.com/bhutan-tour-package-from-bagdogra" />
                    <meta property="og:description" content="Book Bhutan Tour Package from Bagdogra bast deal from Adorable Vacation LLP. Call Now: +91-98833-59713" />
                </Helmet>
                <MainHeader />
                <Bagdogra_6N_7D_Package
                    HotPackagesData={this.state.HotPackagesData}
                    ReviewersData={this.state.ReviewersData}
                />
                <FooterDesktop />
            </>
        );
    }
}

export default Bagdogra_6N_7D_Page;