import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BookingBox from '../BookingBox';
import Slider from "react-slick";
import ApiURL from "../../../api/ApiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InclusionExclusion from '../Bhutan/InclusionExclusion_bagdogra_group';


class Bagdogra_6N_7D_Package extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customActiveTab: 'tab1',
            daywiseActiveTab: 'day1'
        }
    }

    handleCustomTabClick = (tab) => {
        this.setState({
            customActiveTab: tab,
        });
    };

    handleDayWiseTabClick = (tab) => {
        this.setState({
            daywiseActiveTab: tab,
        });
    };

    handleClick = () => {
        const currentUrl = window.location.href;
        const pageTitle = document.title;
        const message = `Hi, Please send about the Tour Package details "${pageTitle}"`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=+919674738480&text=${encodeURIComponent(message + ' ' + currentUrl)}`;
        window.open(whatsappLink, '_blank');
    };

    render() {
        const { customActiveTab, daywiseActiveTab } = this.state;
        const packageTopBannerImageURL = ApiURL.BhutanTourPackageFromBagdograImageURL + "/Bhutan-Tour Package-from-Bagdogra-Airport.webp";
        const packageTopBanner2ImageURL = ApiURL.BhutanTourPackageFromBagdograImageURL + "/Bhutan-Packages-from-Bagdogra-Airport.webp";
        const navDay1ImageURL = ApiURL.BhutanTourPackageFromBagdograImageURL + "/bhutan-tour-package-from-bagdogra-via-phuentsholing.webp";
        const navDay2ImageURL = ApiURL.BhutanTourPackageFromBagdograImageURL + "/bhutan-tour-package-from-bagdogra-and-transfer-to-thimphu.webp";
        const navDay3ImageURL = ApiURL.BhutanTourPackageFromBagdograImageURL + "/with-bhutan-trip-from-bagdogra-explore-dochula-pass.webp";
        const navDay4ImageURL = ApiURL.BhutanTourPackageFromBagdograImageURL + "/bhutan-package-tour-booking-from-bagdogra-airport-explore-buddha-dordenma.webp";
        const navDay5ImageURL = ApiURL.BhutanTourPackageFromBagdograImageURL + "/with-bhutan-tour-from-bagdogra-trek-taktshang-monastery.webp";
        const navDay6ImageURL = ApiURL.BhutanTourPackageFromBagdograImageURL + "/Bhutan-Package-Tour-Itinerary-from-Bagdogra.webp";
        const navDay7ImageURL = ApiURL.BhutanTourPackageFromBagdograImageURL + "/Bhutan-Premium-Package-from-Bagdogra-Airport.webp";
        const navDay8ImageURL = ApiURL.BhutanTourPackageFromBagdograImageURL + "/.webp";
        const TopbannerImageURL = ApiURL.BhutanTourPackageFromBagdograImageURL + "/Bhutan-Holidays-Package-from-Bagdogra-Airport.webp";
        const sightseen1ImageURL = ApiURL.BhutanTourPackageFromBagdograImageURL + "/bhutan-package-tour-from-bagdogra-with-adorable-vacation.webp";
        const sightseen2ImageURL = ApiURL.BhutanTourPackageFromBagdograImageURL + "/bhutan-package-tour-from-bagdogra-with-best-hotels.webp";
        const sightseen3ImageURL = ApiURL.BhutanTourPackageFromBagdograImageURL + "/best-time-to-book-bhutan-package-tour-from-bagdogra-airport.webp";

        const hotPackagesDataList = this.props.HotPackagesData;
        const hotPackagesView = hotPackagesDataList.length > 0 ?
            (
                hotPackagesDataList.map((dataItem, i) => {
                    const hotelImgURL = ApiURL.FIFO_6N_7D_ImageURL + "/" + dataItem.ImageName;
                    const contactURL = dataItem.Url;
                    return (<div className="col-md-12 p-0" key={i}>
                        <div className="card h-100">
                            <img src={hotelImgURL} className="card-img-top" alt={dataItem.AllternateText} />
                            <div className="card-body">
                                <h6 className="card-title">{dataItem.Title} | {dataItem.EXPARO}<span> &#8377; {dataItem.PricePerNight}</span> </h6>
                                <p className="card-text">
                                    <i className="fa fa-building tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-utensils tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-car-side tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-male tomato"></i>&nbsp;&nbsp;
                                    <i className="fa fa-camera tomato"></i>
                                </p>
                                <p className="card-text"><span>{dataItem.Description}</span></p>
                                <p>
                                    <span><b className="tomato">{dataItem.NoOfTours} </b>Tours</span> | &nbsp;
                                    <span><b className="tomato">{dataItem.NoOfDeparture} </b>Departures</span> | &nbsp;
                                    <span><b className="sdf-blue">{dataItem.SDFInclude}</b></span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted"><strong className="tomato">{dataItem.MutedText}</strong></small>
                                <a href={contactURL} className="btn btn-Warning btn-sm float-end">Call Now</a>
                            </div>
                        </div>
                    </div>)
                })
            ) : (<div></div>)


        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const reviewersDataList = this.props.ReviewersData;
        const reviewersDataView = reviewersDataList.map((dataItem, i) => {
            const reviewerImageURL = ApiURL.BhutanTourPackageFromBagdograImageURL + "/" + dataItem.ImageName;
            return (
                <div className="testimonial-box" key={i}>
                    <div className="box-top">
                        <div className="profile">
                            <div className="profile-img">
                                <img src={reviewerImageURL} alt="..." />
                            </div>
                            <div className="name-user">
                                <strong>{dataItem.Name}</strong>
                                <span>{dataItem.Location}</span>
                            </div>
                        </div>
                        <div className="reviews">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </div>
                    </div>
                    <div className="client-comment">
                        <p>{dataItem.Comment}</p>
                    </div>
                </div>
            )
        })


        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <img src={packageTopBannerImageURL} className="mobile-image2" alt="..." />
                    </div>
                </div>
                <div className="container-fluid custom-container py-3">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid custom-container py-1">
                                        <h1 className="content-desktop" style={{ fontSize: "2vw" }}><center>Book <strong>Bhutan Package Tour from Bagdogra Airport</strong></center></h1>

                                        <h3 className="content-mobile" style={{ fontSize: "4vw" }}><center>Book <strong>Bhutan Package Tour from Bagdogra Airport</strong></center>
                                            <hr />
                                        </h3>
                                        <div className="row">
                                            <img src={packageTopBanner2ImageURL} className="mobile-image" alt="..." />

                                        </div>
                                        <hr />
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center px-2">
                                            <div className="font-lg">
                                                3 Cities:{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Phuentsholing
                                                </a>
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Thimphu
                                                </a>
                                                &nbsp;{" "}
                                                <a href="#">
                                                    <i className="fa fa-map-marker text-warning mx-2"></i>{" "}
                                                    Paro
                                                </a>
                                            </div>
                                            <div className="reviews mx-5">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div>
                                        </div>
                                        <p className="p-2">
                                            If you are looking for a memorable <strong>Bhutan Tour from Bagdogra</strong>, you have come to the right place!
                                        </p>
                                        <hr />
                                    </div>
                                    <br />
                                    <h2 className="content-desktop p-4" style={{ fontSize: "1.4vw" }}> FOR BOOKING <strong>BHUTAN PACKAGES FROM BAGDOGRA</strong> <a href='tel:9883359713' class="tomato">☎ CALL Mr. SOUMALYA +91- 98833-59713</a></h2>
                                    <h2 className="content-mobile p-4" style={{ fontSize: "4.5vw" }}> FOR BOOKING <strong>BHUTAN PACKAGES FROM BAGDOGRA</strong> <a href='tel:9883359713' class="tomato">☎ CALL Mr. SOUMALYA +91- 98833-59713</a></h2>

                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> INDIA'S LARGEST <strong>BHUTAN TOUR OPERATOR</strong> (OWN OFFICE AT
                                                THIMPU)</h3>
                                            <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}> INDIA'S LARGEST <strong>BHUTAN TOUR OPERATOR</strong> (OWN OFFICE AT
                                                THIMPU)</h3>
                                        </div>
                                        <div className="card-body">
                                            <p>If you're seeking happiness and peace of mind, we recommend you to book a <strong>Bhutan package tour from Bagdogra Airport</strong>. Bhutan is a small, beautiful country nestled in the Himalayas. It's famous for the idea of Gross National Happiness, which focuses on the well-being of its people. Bhutan is known for its untouched natural beauty. While many places in the world are becoming more urbanized, Bhutan is unique because it's committed to preserving its greenery, covering over 70% of its land. This makes Bhutan one of the few places known for its ecological efforts, even being the world's first carbon-negative country.
                                            </p>
                                            <p>Bhutan was isolated for a long time, but it opened its doors to tourists in 1974. Since then, people have been fascinated by its beauty and culture. Today, Bhutan attracts visitors who love peace, nature, and adventure. The country offers various activities like trekking, rafting, cycling, and bird watching.</p>
                                            <p>Bhutan boasts a rich culture seen in its festivals, known as Tshechus. These vibrant celebrations are a major attractions for tourists that inspire them to book a  Bhutan tour package from Bagdogra Airport. Visitors can explore ancient dzongs (fortresses), monasteries, traditional villages, and enjoy the warm hospitality of its people. Traveling to Bhutan with Adorable Vacation promises a once-in-a-lifetime experience. Join us for a customized <strong>Bhutan tour from Bagdogra</strong> and discover the purity, tranquility, and hospitality of this enchanting country.</p>
                                            <p>Apart from this <strong>Bhutan Tour from Bagdogra Airport</strong>, Adorable Vacation LLP - the Best Bhutan DMC in India also offers personalized and Best Special Chertered Mumbai to Bhutan, Bhutan Package Tour from Pune, Bhutan Package Tour from Delhi, Bhutan Package Tour from Kolkata, Bhutan Package Tour from Ahmedabad, Bhutan Package Tour from Bangalore, Bhutan Package Tour from Jaigaon, Bhutan Package Tour from Chennai at Best Price.</p>
                                            <p>In addition to that <b>Bagdogra to Bhutan Packages,</b> Adorable vacation LLP- the <b>Best Bhutan DMC in India</b> also offers personalized and flexible <a href="/mumbai-special-chartered-flight" target="_blank"><b className="tomato">Special Chertered Mumbai to Bhutan </b></a>,<a href="/bhutan-tour-package-from-mumbai" target="_blank"><b className="tomato">Bhutan Package Tour from Mumbai</b></a>,<a href="/bhutan-tour-package-from-pune" target="_blank"><b className="tomato">Bhutan Package Tour from Pune</b></a>,<a href="/bhutan-tour-from-delhi" target="_blank"><b className="tomato"> Bhutan Package Tour from Delhi</b></a>, <a href="/bhutan-tour-from-kolkata" target="_blank"><b className="tomato">Bhutan Package Tour from Kolkata</b></a>, <a href="/bhutan-tour-package-from-ahemdabad" target="_blank"><b className="tomato">Bhutan Package Tour from Ahmedabad</b></a>, <a href="/bhutan-tour-from-bangalore" target="_blank"><b className="tomato">Bhutan Package Tour from Bangalore</b></a>,<a href="/bhutan-tour-package-from-jaigaon" target="_blank"><b className="tomato"> Bhutan Package Tour from Jaigaon</b></a>, <a href="/bhutan-tour-package-from-chennai" target="_blank"><b className="tomato">Bhutan Package Tour from Chennai</b></a>,<a href="/bhutan-tour-package-from-surat" target="_blank"><b className="tomato">Bhutan Package Tour from Surat</b></a> etc at Best Price.</p>
                                            <br />
                                            <div className="content-desktop">
                                                <center><img src={sightseen1ImageURL} alt="..." /></center>
                                            </div>

                                            <div className="content-mobile2">
                                                <center><img src={sightseen1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                    <br />
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="container-fluid py-3">
                                                <div className="row">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr className="table-danger">
                                                                <th><b>DAY WISE SUMMARY FOR 6N/7D</b> <strong>BHUTAN TOUR FROM BAGDOGRA</strong></th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td
                                                                    className="clickable"
                                                                    data-target="panelsStayOpen-headingOne"
                                                                >
                                                                    <a href="#day1" style={{ color: 'black' }}><b>DAY 1 → </b>
                                                                        <span>
                                                                            BAGDOGRA AIRPORT TO PHUENTSHOLING
                                                                        </span>
                                                                    </a>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td
                                                                    className="clickable"
                                                                    data-target="panelsStayOpen-headingTwo"
                                                                >
                                                                    <a href="#day2" style={{ color: 'black' }}><b>DAY 2 → </b>
                                                                        <span>PHUENTSHOLING TO THIMPHU </span>
                                                                    </a>

                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td
                                                                    className="clickable"
                                                                    data-target="panelsStayOpen-headingThree"
                                                                >
                                                                    <a href="#day3" style={{ color: 'black' }}><b>DAY 3 → </b>
                                                                        <span>THIMPHU TO PUNAKHA EXCURSION</span>
                                                                    </a>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td
                                                                    className="clickable"
                                                                    data-target="panelsStayOpen-headingFour"
                                                                >
                                                                    <a href="#day4" style={{ color: 'black' }}><b>DAY 4 → </b>
                                                                        <span>THIMPHU LOCAL SIGHT SEEN AND TRANSFER TO PARO</span>
                                                                    </a>

                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td
                                                                    className="clickable"
                                                                    data-target="panelsStayOpen-headingFive"
                                                                >
                                                                    <a href="#day5" style={{ color: 'black' }}><b>DAY 5 → </b>
                                                                        <span>Paro Tiger Nest Excursion</span>
                                                                    </a>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td
                                                                    className="clickable"
                                                                    data-target="panelsStayOpen-headingSix"
                                                                >
                                                                    <a href="#day5" style={{ color: 'black' }}><b>DAY 6 → </b>
                                                                        <span>Chele La Pass (Day excursion) and Transfer to Phuentsholing</span>
                                                                    </a>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td
                                                                    className="clickable"
                                                                    data-target="panelsStayOpen-headingSeven"
                                                                >
                                                                    <a href="#day7" style={{ color: 'black' }}><b>DAY 7 → </b>
                                                                        <span> Departure Day – Paro – Phuntsholing for the Exit </span>
                                                                    </a>

                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> WHY <strong>BHUTAN TOUR FROM BAGDOGRA</strong> IS BEST OPTION FROM MUMBAI / PUNE / KOLKATA ?</h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> WHY <strong>BHUTAN TOUR FROM BAGDOGRA</strong> IS BEST OPTION FROM MUMBAI / PUNE / KOLKATA ?</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Are you planning your holidays for the upcoming season? If you're wondering where to go, consider Bhutan—a country near India with stunning natural beauty, a peaceful environment, clean air, rich cultural heritage, and located in the mighty Himalayas.</p>
                                            <p>Bhutan was hidden from the world for many centuries, but in the 1970s, it opened up to tourism and has since become a popular destination globally. Traveling to Bhutan from major Indian cities like Bangalore, Mumbai, Pune, Chennai, and Delhi has become easier thanks to Adorable Vacation, known as India's top specialist in Bhutan tours. They offer excellent <strong>Bhutan tour packages from Bagdogra Airport</strong>.
                                            </p>
                                            <p>Bagdogra Airport connects well with these major cities through domestic flights, ensuring a convenient travel experience to Bhutan. Once you depart from Bagdogra Airport, you'll discover Bhutan's rich culture and natural beauty. From the stunning landscapes adorned with monasteries and fortresses (dzongs) to the iconic Taktsang Monastery (Tiger's Nest) and the bustling markets of Thimphu, Bhutan promises unforgettable experiences.</p>
                                            <p>In summary, embarking on a <strong>Bhutan tour from Bagdogra Airport</strong> is a seamless and captivating journey for travelers from Mumbai, Delhi, Chennai, and Bangalore. Bagdogra's excellent flight connections make it the perfect starting point for your Bhutan adventure. Get ready to immerse yourself in Bhutan's cultural wonders and breathtaking scenery as you embark on this unforgettable journey.</p>
                                        </div>
                                    </div>
                                    <br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}> FLIGHTS YOU CAN CHOOSE FOR <strong>BHUTAN TOUR PACKAGES FROM BAGDOGRA AIRPORT</strong></h3>
                                            <h4 className="content-mobile2" style={{ fontSize: "3.5vw" }}> FLIGHTS YOU CAN CHOOSE FOR <strong>BHUTAN TOUR PACKAGES FROM BAGDOGRA AIRPORT</strong></h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Airlines</th>
                                                            <th>Flight Number</th>
                                                            <th>City</th>
                                                            <th>Departure Time</th>
                                                            <th>Arrival Time</th>
                                                            <th>Travel Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td data-title="Airlines">IndiGo</td>
                                                            <td data-title="Flight Number">6E - 5305</td>
                                                            <td data-title="City">Mumbai</td>
                                                            <td data-title="DEP Time">8:05</td>
                                                            <td data-title="ARRV Time">10:50</td>
                                                            <td data-title="Travel Time">2h 55m</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Airlines">Akasa Air</td>
                                                            <td data-title="Flight Number">QP - 1385</td>
                                                            <td data-title="City">Mumbai</td>
                                                            <td data-title="DEP Time">11:30</td>
                                                            <td data-title="ARRV Time">14:25</td>
                                                            <td data-title="Travel Time">2h 55m</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Airlines">Akasa Air</td>
                                                            <td data-title="Flight Number">QP - 1850</td>
                                                            <td data-title="City">Bangalore</td>
                                                            <td data-title="DEP Time">9:10</td>
                                                            <td data-title="ARRV Time">12:10</td>
                                                            <td data-title="Travel Time">3h 00m</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Airlines">Air India</td>
                                                            <td data-title="Flight Number">I5 - 1780</td>
                                                            <td data-title="City">Bangalore</td>
                                                            <td data-title="DEP Time">5:05</td>
                                                            <td data-title="ARRV Time">8:05</td>
                                                            <td data-title="Travel Time">3h 00m</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Airlines">IndiGo</td>
                                                            <td data-title="Flight Number">6E - 6518</td>
                                                            <td data-title="City">Chennai</td>
                                                            <td data-title="DEP Time">12:05</td>
                                                            <td data-title="ARRV Time">14:40</td>
                                                            <td data-title="Travel Time">2h 35m</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Airlines">IndiGo</td>
                                                            <td data-title="Flight Number">6E - 149</td>
                                                            <td data-title="City">Hyderabad</td>
                                                            <td data-title="DEP Time">10:25</td>
                                                            <td data-title="ARRV Time">12:35</td>
                                                            <td data-title="Travel Time">2h 10m</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Airlines">IndiGo</td>
                                                            <td data-title="Flight Number">6E - 6872</td>
                                                            <td data-title="City">Hyderabad</td>
                                                            <td data-title="DEP Time">8:25</td>
                                                            <td data-title="ARRV Time">10:35</td>
                                                            <td data-title="Travel Time">2h 10m</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Airlines">IndiGo</td>
                                                            <td data-title="Flight Number">6E - 5317</td>
                                                            <td data-title="City">Delhi</td>
                                                            <td data-title="DEP Time">7:30</td>
                                                            <td data-title="ARRV Time">9:35</td>
                                                            <td data-title="Travel Time">2h 05m</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-title="Airlines">Air India</td>
                                                            <td data-title="Flight Number">I5 - 552</td>
                                                            <td data-title="City">Delhi</td>
                                                            <td data-title="DEP Time">9:55</td>
                                                            <td data-title="ARRV Time">12:00</td>
                                                            <td data-title="Travel Time">2h 05m</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>

                                    <br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHY BOOK <strong>BHUTAN PACKAGE TOUR FROM BAGDOGRA</strong> WITH ADORABLE VACATION ?</h3>
                                            <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHY BOOK <strong>BHUTAN PACKAGE TOUR FROM BAGDOGRA</strong> WITH ADORABLE VACATION ?</h3>
                                        </div>
                                        <div className="card-body">
                                            <p>
                                                When it comes to booking your <strong>Bhutan tour packages from Bagdogra</strong>, Adorable Vacation LLP stands out extraordinary for numerous reasons:
                                            </p>
                                            <p>
                                                <b>Years of Expertise:</b> With more than 10+ years of experience in the travel industry, we have honed our skills in creating unforgettable journeys for our clients. Our expertise ensures that your <strong>Bhutan tour from Bagdogra</strong> is seamless and truly memorable.
                                            </p>
                                            <p><b>Bhutan Experts:</b> Having our own office in Bhutan, located at Olakha, Thimphu sets us apart as true Bhutan experts. Our in-depth understanding of the country, its culture, and its hidden gems ensures that you experience the authentic essence of Bhutan during your <strong>Bhutan packages from Bagdogra</strong> with Adorable Vacation.</p>
                                            <p><b>Quality Accommodation:</b> We offer stays in some of the best hotels in Bhutan ranging from TCB approved 3 Star hotels to luxurious 5 Star property providing you with comfort and luxury throughout your journey. We work with some of the best hotels in Bhutan like Terma Linca Resort & Spa, Pemako, Le Méridien Hotels & Resorts, COMO by UMA, Six Senses, Dhensa Boutique Resort, RKPO Green Resort, Zhingkham Resort, Tashi Namgay Resort, and Hotel Vara etc.</p>
                                            <p><b>Ethical and Responsible Tourism:</b> At Adorable Vacation LLP, we are committed to promoting sustainable tourism practices. By traveling with us, you can rest assured that your journey contributes positively to Bhutan’s environment and communities. Choose Adorable Vacation LLP for an extraordinary <strong>Bhutan tour from Bagdogra Airport</strong> and immerse yourself in the <b>Land of Happiness</b>. Our TCB License No. 1051529 and registration with the Tourism Council of Bhutam assure you of our credibility and dedication to offering you the best travel experience. Let us be your trusted travel companion as you embark on this magical journey to the Land of Happiness.</p>
                                            <p><b>Local Connections:</b> Our local team in Bhutan is dedicated to providing you with the best local guides, immersive experiences, and valuable insights into the country’s culture and traditions. Our experienced Bhutan tour manager takes pride in sharing his/her profound insights and ensuring that you have a deeper understanding of Bhutan’s way of life, values, and customs.</p>
                                            <p><b>Personalized Itineraries:</b> At Adorable Vacation LLP, we believe in tailoring your journey to suit your preferences and interests. Our personalised <strong>Bhutan tour itineraries from Bagdogra Airport</strong> guarantee that every moment of your <strong>Bhutan trip</strong> is unique and tailored just for you.</p>
                                            <a href="tel://9883359713" className="btn btn-Warning">
                                                <b>CALL NOW & TALK TO OUR EXPERT</b>
                                            </a>
                                        </div>
                                    </div>
                                    <br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>HOTELS WE PROVIDE FOR <strong>BHUTAN PACKAGE TOUR FROM BAGDOGRA</strong></h3>
                                            <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>HOTELS WE PROVIDE FOR <strong>BHUTAN PACKAGE TOUR FROM BAGDOGRA</strong></h3>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th>Destination</th>
                                                            <th><center>Hotels Name</center></th>
                                                            <th>Hotel Type</th>
                                                            <th>No of Nights</th>
                                                            <th>Meal Plan</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr className="text-center">
                                                            <td data-title="Destination">Phuentsholing</td>
                                                            <td data-title="Hotels Name"> Hotel Tsheringma /
                                                                Meto Pema Hotel</td>
                                                            <td data-title="Hotel Type">3 Star</td>
                                                            <td data-title="No of Nights">2N</td>
                                                            <td data-title="Meal Plan">Room with All Meals</td>
                                                        </tr>
                                                        <tr className="text-center">
                                                            <td data-title="Destination">Thimphu</td>
                                                            <td data-title="Hotels Name">Peaceful Resort / Hotel Ugyen</td>
                                                            <td data-title="Hotel Type">3 Star</td>
                                                            <td data-title="No of Nights">2N</td>
                                                            <td data-title="Meal Plan">Room with All Meals</td>
                                                        </tr>
                                                        <tr className="text-center">
                                                            <td data-title="Destination">Paro</td>
                                                            <td data-title="Hotels Name">Tshongdu Boutique / Penchu Boutique Hotel /
                                                                Hotel Center Point</td>
                                                            <td data-title="Hotel Type">3 Star</td>
                                                            <td data-title="No of Nights">2N</td>
                                                            <td data-title="Meal Plan">Room with All Meals</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <br /><br />
                                    <div className="card">
                                        <div
                                            className="card-header custHeaer"
                                        >
                                            <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}><strong>BHUTAN TOUR COST FROM BAGDOGRA </strong>WITH 3 STAR CATEGORY HOTELS</h3>
                                            <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}><strong>BHUTAN TOUR COST FROM BAGDOGRA </strong>WITH 3 STAR CATEGORY HOTELS</h3>
                                        </div>
                                        <div className="card-body">
                                            <div id="no-more-tables">
                                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                                    <thead className="cf">
                                                        <tr className="table-danger">
                                                            <th className="text-center">No of Pax</th>
                                                            <th className="text-center">Bhutan Package Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr className="text-center">
                                                            <td data-title="No of Pax">02 Adults</td>
                                                            <td data-title="Package Price">57,900/P</td>
                                                        </tr>
                                                        <tr className="text-center">
                                                            <td data-title="No of Pax">04 Adults</td>
                                                            <td data-title="Package Price">44,600/P</td>
                                                        </tr>
                                                        <tr className="text-center">
                                                            <td data-title="No of Pax">06 Adults</td>
                                                            <td data-title="Package Price">39,900/P</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br />
                                            <div className="content-desktop">
                                                <center><img src={sightseen2ImageURL} alt="..." /></center>
                                            </div>

                                            <div className="content-mobile2">
                                                <center><img src={sightseen2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>WHICH IS THE BEST SEASON TO BOOK <strong>BHUTAN PACKAGE TOUR FROM BAGDOGRA ?</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>WHY BOOK <strong>BHUTAN PACKAGE TOUR FROM BAGDOGRA</strong> WITH ADORABLE VACATION</h3>
                                </div>
                                <div className="card-body">
                                    <p>
                                        Nestled peacefully in the lap of the Himalayas, Bhutan is a small country that experiences a wide range of seasonal changes. Each season—Summer, Winter, Spring, and Autumn—brings its own beauty and natural wonders, making Bhutan a year-round destination for travellers.
                                    </p>
                                    <p>
                                        <b>Autumn</b> [September to November], is one of the best time to <strong>travel Bhutan</strong>. The weather is pleasant with little to no rain, perfect for traveling and sightseeing. This season is also famous for vibrant festivals like the Black-Necked Crane Festival, Paro Tshechu, and Punakha Tshechu. Dzongs (fortresses) and monasteries come alive with colorful flags and traditional dances, making it an ideal time to book a <strong>Bhutan tour package from Bagdogra</strong>.
                                    </p>
                                    <p><b>Winter</b> [December to February] in Bhutan can be harsh, especially in the northern regions where temperatures drop below freezing and heavy snowfall covers cities like Thimphu, Paro, and Punakha. Despite the cold, Bhutan celebrates several festivals during this season, such as Monggar Tshechu, Trashigang Tshechu, Lhuntse Tshechu, and Trongsa Tshechu. Winter offers a unique perspective of Bhutan, and some travellers enjoy visiting during this snowy time.</p>

                                    <p>According to Adorable Vacation, the best time to book a <strong>Bhutan package tour from Bagdogra</strong> is during spring (March to May). This season blankets the valleys of Bhutan and streets with blooming Jacaranda and Rhododendron flowers, creating a vibrant and picturesque atmosphere. The weather is mild and clear, perfect for famous treks like Jomolhari and Drukpath. Spring attracts lots of travellers from around the world, especially from Europe, North America, China, Southeast Asia, and Australia.</p>
                                    <br />
                                    <div className="content-desktop">
                                        <center><img src={sightseen3ImageURL} alt="..." /></center>
                                    </div>

                                    <div className="content-mobile2">
                                        <center><img src={sightseen3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                    </div>
                                    <br />
                                    <p>From June to August, Bhutan experiences the monsoon season. This period sees frequent drizzles and heavy rains that can disrupt daily life in the hilly regions, leading to landslides and road closures. It's generally not the best time for any <strong>Bhutan tour plan from Bagdogra</strong>.</p>
                                    <p>No matter the season, Bhutan offers a distinct and memorable experience for travellers seeking natural beauty, cultural richness, and adventure.</p>

                                    <a href="tel://9883359713" className="btn btn-Warning">
                                        <b>CALL NOW & TALK TO OUR EXPERT</b>
                                    </a>
                                </div>
                            </div>
                            <br /><br />
                            <div className="card">
                                <div
                                    className="card-header custHeaer"
                                >
                                    <h3 className="content-desktop" style={{ fontSize: "1.5vw" }}>TEMPERATURE TO NOTE FOR <strong>BAGDOGRA TO BHUTAN TRIP</strong></h3>
                                    <h3 className="content-mobile2" style={{ fontSize: "3.5vw" }}>TEMPERATURE TO NOTE FOR <strong>BAGDOGRA TO BHUTAN TRIP</strong></h3>
                                </div>
                                <div className="card-body">
                                    <div id="no-more-tables">
                                        <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                            <thead className="cf">
                                                <tr className="table-danger">
                                                    <th>TRAVEL MONTHS</th>
                                                    <th>WEATHER</th>
                                                    <th>TEMPERATURE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-title="TRAVEL MONTHS">March to May</td>
                                                    <td data-title="WEATHER">Spring</td>
                                                    <td data-title="TEMPERATURE">10°C to 22°C</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="TRAVEL MONTHS">June to August</td>
                                                    <td data-title="WEATHER">Summer</td>
                                                    <td data-title="TEMPERATURE">15°C to 28°C</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="TRAVEL MONTHS">September to November</td>
                                                    <td data-title="WEATHER">Autumn</td>
                                                    <td data-title="TEMPERATURE">12°C to 26°C</td>
                                                </tr>
                                                <tr>
                                                    <td data-title="TRAVEL MONTHS">December to February</td>
                                                    <td data-title="WEATHER">Winter</td>
                                                    <td data-title="TEMPERATURE">-3°C to 10°C</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <br />
                                </div>
                            </div>
                            <br /><br />
                            {/* <!--By Itinerary--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                DAY WISE <strong><span>BHUTAN TOUR PLAN FROM BAGDOGRA</span></strong>
                                            </h2>
                                            <h4>6 Nights 7 Days <strong>Bhutan Tour Itinerary from Bagdogra Airport</strong></h4>
                                        </div>
                                    </div>
                                </div>


                                {/* <!--end Short description--> */}
                                <br />
                                <div className="row">
                                    <div
                                        className="accordion"
                                        id="accordionPanelsStayOpenExample"
                                    >
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day1"
                                            >
                                                <button
                                                    className="accordion-button"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseOne"
                                                >
                                                    DAY 1  – BAGDOGRA AIRPORT TO PHUENTSHOLING [DISTANCE: 170 KM | 5 HRS]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingOne"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Meet & Greet on arrival at Bagdogra Airport for your much awaited <strong>Bhutan tour packages from Bagdogra</strong> and transfer to Phuentsholing - The Gateway of Bhutan by Road from India. It is a thriving commercial center on the northern edge of the Indian Plains. On arrival check in at the hotel.Overnight stay at hotel in Phuentsholing.

                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay1ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay1ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day2"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                >
                                                    DAY 02: PHUENTSHOLING TO THIMPHU [DISTANCE: 176 KM | 6 HRS]
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseTwo"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingTwo"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After breakfast, proceed towards Thimpu – the capital city of Bhutan via the wonderful mystic town Gedu, about 9000 ft above the sea and Chukha Dam. On the way, halt at Chojum to take photographs of the confluence of two rivers of Bhutan, Thumpu Chu, and Paro Chu. Reach Thimpu in the evening. On arrival check in at the hotel. In the evening, visit the Tashichho Dzong (Fortress of the Glorious Religion). Overnight stay at hotel in Thimphu.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay2ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay2ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day3"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 03: THIMPHU TO PUNAKHA EXCURSION
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingThree"
                                            >
                                                <div className="accordion-body">
                                                    <p>After breakfast today you will explore the Punakha excursion<br /><br />
                                                        <ul>
                                                            <li>&nbsp;• Dochu La Pass</li>
                                                            <li>&nbsp;•  Punakha Dzong</li>
                                                            <li>&nbsp;•  Punakha Suspension Bridge</li>
                                                            <li>&nbsp;•  Punakha Village</li>

                                                        </ul>
                                                    </p>
                                                    <p>
                                                        After Visiting all the sightseeing back to Thimphu, overnight Stay in Thimphu.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay3ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay3ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day4"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    Day 04: - THIMPHU LOCAL SIGHT SEEN AND TRANSFER TO PARO
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFour"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFour"
                                            >
                                                <div className="accordion-body">

                                                    <ul>
                                                        <li>&nbsp;• The National Memorial Chorten</li>
                                                        <li>&nbsp;• Sakyamuni Buddha</li>
                                                        <li>&nbsp;• Changangkha Lhakhang</li>
                                                        <li>&nbsp;• Takin Zoo</li>
                                                        <li>&nbsp;• The Royal Textile Museum </li>
                                                        <li>&nbsp;• Ringpung Dzong Dzong  </li>
                                                    </ul>
                                                    <br />
                                                    <p>After Visit all the sightseeing transfer to Paro. Overnight in Paro. </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay4ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay4ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day5"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 05: PARO TIGER NEST EXCURSION
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseFive"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingFive"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        After early morning breakfast, you will be starting an Excursion tour to Taktsang Monastery. Taksand Palphug Monastery also known as Tiger's Nest is an amazing Buddhist monastery, temple and sacred site located in the upper Paro town. Built in 1692, Padmasambhava is believed to have meditated at this site for 3 years. Guru Padmasambhava is known to be the founder of Buddhism religion in Bhutan. He is immensely respected and has a huge number of sacred followers in Bhutan.  </p>
                                                    <p>Kyichu Lhakhang is one of the oldest Buddhist Temples in Bhutan (if not the oldest) and it is considered to be a prime example of the ornate Bhutanese architecture. Overnight at the Paro.</p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay5ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay5ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day6"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 06: CHELE LA PASS (DAY EXCURSION) AND TRANSFER TO PHUENTSHOLING
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSix"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSix"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                        Chele-la remains one of the least visited areas in the country and retains the air of an unspoiled, primeval forest.
                                                        Drive to Chele La Pass (3,988m) from which on a clear day the view sweeps away to Bhutan’s second-highest sacred peak, Mt Jomolhari (7314m).
                                                        On arrival check in at the hotel. Evening Free for Leisure. Overnight stay at Phuentsholing.
                                                    </p>
                                                    <br />
                                                    <div className="content-desktop">
                                                        <center><img src={navDay6ImageURL} alt="..." /></center>
                                                    </div>

                                                    <div className="content-mobile2">
                                                        <center><img src={navDay6ImageURL} style={{ width: '320px' }} alt="..." /></center>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2
                                                className="accordion-header"
                                                id="day7"
                                            >
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseThree"
                                                >
                                                    DAY 07: DEPARTURE DAY – PARO – PHUNTSHOLING
                                                </button>
                                            </h2>
                                            <div
                                                id="panelsStayOpen-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="panelsStayOpen-headingSeven"
                                            >
                                                <div className="accordion-body">
                                                    <p>
                                                    After breakfast check out from the hotel and move towards Phuntsholing do the exit immigration and proceed to your final destination.
                                                    </p>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                            <br />
                            {/* <!--insert table inclusion exclusion--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            <InclusionExclusion />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <!--end table inclusion exclusion--> */}


                            {/* <!--start of package details--> */}
                            <div className="container-fluid py-5">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2> OTHER <span>BHUTAN TOUR PACKAGES</span></h2>
                                            <h5><strong>Trending Bhutan Trip from Bagdogra Airport</strong></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">

                                                <Slider {...settings}>
                                                    {hotPackagesView}
                                                </Slider>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!--end of package details--> */}
                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h2>
                                                Frequently Ask <span>Questions</span>
                                            </h2>
                                            <h4>Bhutan Trip Related Questions</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div
                                        className="accordion accordion-flush faQStyle"
                                        id="accordionFlushExample"
                                    >
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingOne">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseOne"
                                                >
                                                    Is Bhutan visa free for Indian?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseOne"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingOne"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Yes, Indian citizens do not require a visa to enter
                                                    Bhutan.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingTwo">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseTwo"
                                                >
                                                    How can I go to Bhutan from India?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseTwo"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingTwo"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    You can take a flight to Bagdogra (IXB). After that
                                                    enter to Bhutan by road via PHUENTSHOLING. This will
                                                    be an economical option. In terms of luxury option,
                                                    you can avail Direct Non Stop flight to PARO (PBH)
                                                    airport. Operated from three major cities DELHI,
                                                    KOLKATA & GUWAHATI.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingThree">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseThree"
                                                >
                                                    Do Bhutanese Speak English?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseThree"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingThree"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Dzongkha is the national language of the Bhutan, which
                                                    is predominantly spoken in the western region of
                                                    Bhutan. However, there are 25 dialects spoken in
                                                    Bhutan. Bhutanese people speak English as it is the
                                                    medium of instruction in the schools. Hence it is easy
                                                    to talk to Bhutanese people if you are fluent in
                                                    English. The official documents, road signs, and even
                                                    the major national newspapers are published in English
                                                    making it easier for tourists to understand.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFour">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseFour"
                                                >
                                                    Is Alcohol cheaper in Bhutan?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseFour"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingFour"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Yes, alcohol is cheaper in Bhutan, and the locally
                                                    made alcohol is good in taste, and these beverages act
                                                    as a great souvenir for the boozers. Do try out Ara,
                                                    the traditional hot alcoholic beverage consumed by the
                                                    majority of the population and is made of rice,
                                                    barley, wheat, or millet, and herbs which is either
                                                    fermented or diluted.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingFive">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseFive"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseFive"
                                                >
                                                    Can I use Indian money in Bhutan Tour from Bagdogra?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseFive"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingFive"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Yes, you can carry Indian Note to Bhutan. Except Rs
                                                    2000/- Note, which is not a legal tender in Bhutan?
                                                    However, many merchants in Bhutan now days accept Rs
                                                    2000/- Note.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSix">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSix"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseFive"
                                                >
                                                    How are the Bhutan Mobile / Internet Connectivity?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseSix"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingSix"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Indian Mobile Operators provide the Global Roaming
                                                    Connectivity in Bhutan, However, it is expensive.
                                                    Please check with your operator about Global Roaming
                                                    Activation / Incoming and Out Going Charges. Tourists
                                                    can easily get the Bhutan Tourists SIM card (Tashi
                                                    Cell and BMobile ) at Rs 200/- with data option at Rs
                                                    99/- for 400 MB data.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h4 className="accordion-header" id="flush-headingSeven">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseSeven"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseSeven"
                                                >
                                                    Can I get wine in Bhutan including imported Brands?
                                                </button>
                                            </h4>
                                            <div
                                                id="flush-collapseSeven"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingSeven"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    In Paro Duty free shop, you can get various brands of
                                                    imported foreign Whisky, Wine, Vodka, Beer etc and
                                                    price is relatively cheaper compared to any Indian
                                                    Duty Free shop. In Thimphu, you can get the imported
                                                    wine brand, however in Paro and Punakha, you may get
                                                    the imported brands only in your hotel, not in any off
                                                    shop. However, in Bhutan, availability of local brand
                                                    wines is abundant and they are really worthy to buy.
                                                    While you are in <strong>Bhutan Package Tour</strong>, you must buy
                                                    Local Peach Wine and am confident you will love it.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid py-5">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="site-heading text-center">
                                            <h3><b>Bhutan <span className="tomato">Tour Reviews</span></b></h3>
                                            <h4><i>"Your Experience is our Marketing"</i></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-cols-1">
                                    <div id="testimonials">
                                        <div className="testimonial-box-container">

                                            {reviewersDataView}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-3">

                            <BookingBox />

                        </div>

                    </div>
                </div>
                <div class="btn-call-pulse">
                    <a style={{ color: 'white' }} href="tel:+91-96747-38480">
                        <i className="fa fa-phone"></i>
                    </a>
                </div>
                <div className="btn-whatsapp-pulse">
                    <a style={{ color: 'white' }} href="#" onClick={this.handleClick}>
                        <i className="fab fa-whatsapp"></i>
                    </a>
                </div>
            </>
        );
    }
}

export default Bagdogra_6N_7D_Package;