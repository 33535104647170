class ApiURL {
  ////==========================================================================
  ////Base URL Section
  ////==========================================================================
  ////Local
  //static BaseURL = "http://localhost:8181";
  //static BaseURL = "http://127.0.0.1:8000";
  ////Production
  static BaseURL = "https://api.adorablevacation.com";

  ////==========================================================================
  ////Image URL Section
  ////==========================================================================
  static BaseImageURL = this.BaseURL + "/uploadfiles/images";
  static HomeBannerImageURL = this.BaseImageURL + "/Index/HomeBanner";
  static VisaServicesImageURL = this.BaseImageURL + "/VisaService";
  static DomesticExplorerImageURL = this.BaseImageURL + "/Index/DomesticExplorer";
  static InternationalExplorerImageURL = this.BaseImageURL + "/Index/InternationalExplorer";
  //static TestimonialClientImageURL = "https://mdbcdn.b-cdn.net/img/Photos/Avatars";
  static TestimonialClientImageURL = this.BaseImageURL + "/Index/Testimonial";
  static HotPackageImageURL = this.BaseImageURL + "/Index/HotPackages";
  static AboutUsImageURL = this.BaseImageURL + "/AboutUs";
  static ContactUsImageURL = this.BaseImageURL + "/ContactUs";
  //----------------------------------------------------------------------------

  static BhutanTourPackageImageURL = this.BaseImageURL + "/Packages/Bhutan_Tour_Package";
  static FIFO_4N_5D_ImageURL = this.BaseImageURL + "/Packages/4N_5D_FIFO";
  static FIFO_5N_6D_ImageURL = this.BaseImageURL + "/Packages/5N_6D_FIFO";
  static FIFO_6N_7D_ImageURL = this.BaseImageURL + "/Packages/6N_7D_FIFO";
  static FIFO_7N_8D_ImageURL = this.BaseImageURL + "/Packages/7N_8D_FIFO";
  static FIFO_8N_9D_ImageURL = this.BaseImageURL + "/Packages/8N_9D_FIFO";
  static FIFO_10N_11D_ImageURL = this.BaseImageURL + "/Packages/10N_11D_FIFO";
  static BhutanTourPackageFromDelhiImageURL = this.BaseImageURL + "/Packages/Bhutan_Tour_Package_From_Delhi";
  static BhutanTourPackageFromMumbaiImageURL = this.BaseImageURL + "/Packages/Bhutan_Tour_Package_From_Mumbai";
  static BhutanTourPackageFromKolkataImageURL = this.BaseImageURL + "/Packages/Bhutan_Tour_Package_From_Kolkata";
  static BhutanTourPackageFromChennaiImageURL = this.BaseImageURL + "/Packages/Bhutan_Tour_Package_From_Chennai";
  static BhutanTourPackageFromHyderabadImageURL = this.BaseImageURL + "/Packages/Bhutan_Tour_Package_From_Hyderabad";
  static BhutanTourPackageFromAhemdabadImageURL = this.BaseImageURL + "/Packages/Bhutan_Tour_Package_From_Ahemdabad";
  static BhutanTourPackageFromBangaloreImageURL = this.BaseImageURL + "/Packages/Bhutan_Tour_Package_From_Bangalore";
  static BhutanTourPackageFromPuneImageURL = this.BaseImageURL + "/Packages/Bhutan_Tour_Package_From_Pune";
  static BhutanTourPackageFromJaigaonImageURL = this.BaseImageURL + "/Packages/Bhutan_Tour_Package_From_Jaigaon";
  static BhutanTourPackageFromBagdograImageURL = this.BaseImageURL + "/Packages/Bhutan_Tour_Package_From_Bagdogra";
  static BhutanTourPackageFromSuratImageURL = this.BaseImageURL + "/Packages/Bhutan_Tour_Package_From_Surat";
  //--------------------Meghalaya---------------------
  static MeghalayaTourPackageImageURL = this.BaseImageURL + "/Packages/North_East_Package/Meghalaya_Tour_Package";
  static Megalaya_5N_6D_PackageImageURL = this.BaseImageURL + "/Packages/North_East_Package/5N-6D-Meghalaya";
  static Megalaya_6N_7D_PackageImageURL = this.BaseImageURL + "/Packages/North_East_Package/6N-7D-Meghalaya";
  static Megalaya_7N_8D_PackageImageURL = this.BaseImageURL + "/Packages/North_East_Package/7N-8D-Meghalaya";
  static Arunachal_6N_7D_PackageImageURL = this.BaseImageURL + "/Packages/North_East_Package/6N-7D-Arunachal";
  static Arunachal_10N_11D_PackageImageURL = this.BaseImageURL + "/Packages/North_East_Package/10N-11D-Arunachal";
  static Arunachal_11N_12D_PackageImageURL = this.BaseImageURL + "/Packages/North_East_Package/11N-12D-Arunachal";
  static Shillong_Meghalaya_6N_7D_PackageImageURL = this.BaseImageURL + "/Packages/North_East_Package/6N-7D-Shillong_Meghalaya_guwahati";
  static Kaziranga_Shillong_Cherrapunjee_7N_8D_PackageImageURL = this.BaseImageURL + "/Packages/North_East_Package/Kaziranga-Shillong-Cherapunjee-from_guwahati";
  //-----------------Arunachal-----------------------------------
  static Arunachal_guwahati_15N_16D_PackageImageURL = this.BaseImageURL + "/Packages/North_East_Package/15N-16D-Arunachal_guwahati";
  static ArunachalTourMumbai_14N_15D_PackageImageURL = this.BaseImageURL + "/Packages/North_East_Package/14N-15D-Arunachal_mumbai";
  static ArunachalTourKolkata_9N_10D_PackageImageURL = this.BaseImageURL + "/Packages/North_East_Package/9N-10D-Arunachal-kolkata";
  //--------------------Sikkim-Darjeeling--------------------------
  static Sikkim_4N_5D_PackageImageURL = this.BaseImageURL + "/Packages/Sikkim_Darjeeling/Sikkim_Darjeeling_4N_5D";
  static Sikkim_5N_6D_PackageImageURL = this.BaseImageURL + "/Packages/Sikkim_Darjeeling/sikkim_tour_package_5N_6D";
  static NorthSikkim_Silkroute_8N_9DImageURL = this.BaseImageURL + "/Packages/Sikkim_Darjeeling/NorthSikkim_Silkroute_8N_9D";
  static Sikkim_Bagdogra_Package_9N_10DImageURL = this.BaseImageURL + "/Packages/Sikkim_Darjeeling/Sikkim_Bagdogra_Package_9N_10D";
  //---------------------Andaman----------------
  static Andaman_6N_7D_Package = this.BaseImageURL + "/Packages/Andaman_Package/Andaman_6N_7D_package";
  static Andaman_7N_8D_Package_KolkataImageURL = this.BaseImageURL + "/Packages/Andaman_Package/Andaman_7N_8D_Package_Kolkata";
  static Andaman_5N_6D_HoneymoonPackageImageURL = this.BaseImageURL + "/Packages/Andaman_Package/Andaman_5N_6D_Honeymoon_Package";
  //---------------------Kashmir------------------
  static Kashmir_Tour_Package_from_Srinagar_Airport = this.BaseImageURL + "/Packages/Kashmir/Kashmir-Tour-Package-from-Srinagar-Airport";
  static Kashmir_Tour_Package_with_Doodhpathri_Yusmarg = this.BaseImageURL + "/Packages/Kashmir/Kashmir-Tour-Packages-With-Doodhpathri-Yusmarg";
  static Kashmir_Tour_Package_with_Gurez_Valley = this.BaseImageURL + "/Packages/Kashmir/Kashmir-Tour-Packages-With-Gurez-valley";
  static Kashmir_Tour_Package_with_Sinthan_Top = this.BaseImageURL + "/Packages/Kashmir/Kashmir-Tour-Packages-With-Sinthan-Top";
  static Kashmir_Tour_Package_with_Vaishno_Devi = this.BaseImageURL + "/Packages/Kashmir/Kashmir-Tour-Packages-With-Vaishno-devi";
  static Kashmir_Tour_Package_with_Tulip = this.BaseImageURL + "/Packages/Kashmir/Kashmir-Tour-Packages-With-Tulip";
  static Kashmir_Tour_Package_from_Kolkata = this.BaseImageURL + "/Packages/Kashmir/Kashmir-Tour-Packages-from-kolkata";
  static Kashmir_Tour_Package_from_Surat = this.BaseImageURL + "/Packages/Kashmir/Kashmir-Tour-Packages-from-Surat";
  static Kashmir_Tour_Package_from_Chennai = this.BaseImageURL + "/Packages/Kashmir/Kashmir-Tour-Packages-from-Chennai";
  static Kashmir_Tour_Package_from_Bangalore = this.BaseImageURL + "/Packages/Kashmir/Kashmir-Tour-Packages-from-Bangalore";
  static Kashmir_Tour_Package_from_Pune = this.BaseImageURL + "/Packages/Kashmir/Kashmir-Tour-Packages-from-Pune";
  static Kashmir_Tour_Package_from_Mumbai = this.BaseImageURL + "/Packages/Kashmir/Kashmir-Tour-Packages-from-Mumbai";
  static Kashmir_Tour_Package_from_Delhi = this.BaseImageURL + "/Packages/Kashmir/Kashmir-Tour-Packages-from-Delhi";
  static Kashmir_Tour_Package_from_Ahmedabad = this.BaseImageURL + "/Packages/Kashmir/Kashmir-Tour-Packages-from-Ahmedabad";
  static Kashmir_Family_Package_Tour = this.BaseImageURL + "/Packages/Kashmir/Kashmir-Family-Package-Tour";
  static Kashmir_Vaishno_devi_Amritsar_Tour_Package = this.BaseImageURL + "/Packages/Kashmir/Kashmir-Vaishno-devi-Amritsar-Tour-Package";
   //---------------------Ladakh------------------
   static Ladakh_Tour_Package_6N_7D = this.BaseImageURL + "/Packages/Ladakh/Ladakh-Tour-Package-6N-7D";
   static leh_ladakh_kargil_8N_9D = this.BaseImageURL + "/Packages/Ladakh/leh-ladakh-kargil-8N-9D";
   static Leh_Ladakh_Turtuk_Package = this.BaseImageURL + "/Packages/Ladakh/Leh-Ladakh-Turtuk-Package";
   static Kashmir_Leh_Ladakh_Tour_PackageImageURL = this.BaseImageURL + "/Packages/Kashmir/Kashmir-Leh-Ladakh-Tour-Package";
   //----------------------Thailand----------------
   static Thailand_8N_9D_Package = this.BaseImageURL + "/Packages/Thailand/Thailand-Tour-Package-8N-9D";
   static BangkokPattaya_8N_9D_Package = this.BaseImageURL + "/Packages/Thailand/Bangkok-Pattaya-Tour-Package-8N-9D";
   //----------------------Vietnam-----------------
   static Vietnam_7N_8D_Package = this.BaseImageURL + "/Packages/Vietnam/Vietnam_7N_8D_Package";
   //-----------------------Sundarban---------------------------
   static Sundarban_Sonarbangla_PackageImageURL = this.BaseImageURL + "/Packages/Sundarban/Sundarban_Sonarbangla_Tour";
   static Sundarban_Kolkata_PackageImageURL = this.BaseImageURL + "/Packages/Sundarban/sundarban_kolkata";
   //----------------------Sandakphu
   static Sandakphu_trekking_PackageImageURL = this.BaseImageURL + "/Packages/Sandakphu/sandakphu_trekking_Package";
   static Sandakphu_land_rover_PackageImageURL = this.BaseImageURL + "/Packages/Sandakphu/sandakphu_land_rover_Package";
  ////===========================================================================
  ////API URL Section
  ////===========================================================================
  static SaveCustomerEnquery = this.BaseURL + "/api/customer/saveCustomerEnquery/";
  static GetAllOriginCities = this.BaseURL + "/api/customer/getAllOriginCities/";
  // static ContactUs = this.BaseURL + "/api/customer/contactUs";
  // static title = this.BaseURL + "/pages/";

  ////=========================================================================
  /*static ProductListByCategory(Cat1Name) {
    return this.BaseURL + "/api/product/productListByCategory/" + Cat1Name;
  }
  static ProductListBySubcategory(Cat1Name, Cat2Name) {
    return this.BaseURL + "/api/product/productListBySubcategory/" + Cat1Name + "/" + Cat2Name;
  } */


}

export default ApiURL;
